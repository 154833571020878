import {
    useTenantSetting,
    ResidentialSituationTypes,
} from '@nestoca/multi-tenant';

import { useI18n } from 'providers/i18n/use-i18n';

export type AddressSituation =
    (typeof ResidentialSituationTypes)[keyof typeof ResidentialSituationTypes];

export const ADDRESS_SITUATION_TYPE_MAP: Record<
    AddressSituation,
    {
        id: AddressSituation;
        labelKey: string;
    }
> = {
    [ResidentialSituationTypes.RENTING]: {
        id: ResidentialSituationTypes.RENTING,
        labelKey: 'renting',
    },
    [ResidentialSituationTypes.OWNER]: {
        id: ResidentialSituationTypes.OWNER,
        labelKey: 'owner',
    },
    [ResidentialSituationTypes.LIVING_WITH_PARENTS]: {
        id: ResidentialSituationTypes.LIVING_WITH_PARENTS,
        labelKey: 'livingWithParents',
    },
    [ResidentialSituationTypes.OWN_KEEPING]: {
        id: ResidentialSituationTypes.OWN_KEEPING,
        labelKey: 'ownKeeping',
    },
    [ResidentialSituationTypes.OWN_KEEPING_OUT]: {
        id: ResidentialSituationTypes.OWN_KEEPING_OUT,
        labelKey: 'ownKeepingOut',
    },
    [ResidentialSituationTypes.OWN_SELLING]: {
        id: ResidentialSituationTypes.OWN_SELLING,
        labelKey: 'ownSelling',
    },
    [ResidentialSituationTypes.OTHER]: {
        id: ResidentialSituationTypes.OTHER,
        labelKey: 'other',
    },
};

export const useAddressSituationOptions = () => {
    const { i18n } = useI18n();

    const {
        settings: { residentialSituationTypes },
    } = useTenantSetting('typesOverride');

    if (!residentialSituationTypes?.length) {
        return [];
    }

    return residentialSituationTypes.map((type) => ({
        value: type,
        label: i18n._(ADDRESS_SITUATION_TYPE_MAP[type].labelKey || ''),
    }));
};
