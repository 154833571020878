import { useTenantSettings } from '@nestoca/multi-tenant';
import { OtherIncomeTypes } from '@nestoca/multi-tenant';

import { useI18n } from 'providers/i18n/use-i18n';

type OtherIncome = typeof OtherIncomeTypes;

type OtherIncomeValue = OtherIncome[keyof OtherIncome];

type OtherIncomeMap = Record<
    OtherIncomeValue,
    {
        id: OtherIncomeValue;
        labelKey: string;
    }
>;

export const OTHER_INCOME_MAP: OtherIncomeMap = {
    [OtherIncomeTypes.INVESTMENT_INCOME]: {
        id: OtherIncomeTypes.INVESTMENT_INCOME,
        labelKey: 'incomeType.investment',
    },
    [OtherIncomeTypes.INTEREST_INCOME]: {
        id: OtherIncomeTypes.INTEREST_INCOME,
        labelKey: 'incomeType.interest',
    },
    [OtherIncomeTypes.ALIMONY]: {
        id: OtherIncomeTypes.ALIMONY,
        labelKey: 'incomeType.alimony',
    },
    [OtherIncomeTypes.CHILD_SUPPORT]: {
        id: OtherIncomeTypes.CHILD_SUPPORT,
        labelKey: 'incomeType.childSupport',
    },
    [OtherIncomeTypes.EMPLOYMENT_EXPENSE]: {
        id: OtherIncomeTypes.EMPLOYMENT_EXPENSE,
        labelKey: 'incomeType.employment',
    },
    [OtherIncomeTypes.DISABILITY_INCOME]: {
        id: OtherIncomeTypes.DISABILITY_INCOME,
        labelKey: 'incomeType.disability',
    },
    [OtherIncomeTypes.PENSION]: {
        id: OtherIncomeTypes.PENSION,
        labelKey: 'incomeType.pension',
    },
    [OtherIncomeTypes.CHILD_CARE_BENEFIT]: {
        id: OtherIncomeTypes.CHILD_CARE_BENEFIT,
        labelKey: 'incomeType.childCareBenefit',
    },
    [OtherIncomeTypes.FOSTER_CARE_INCOME]: {
        id: OtherIncomeTypes.FOSTER_CARE_INCOME,
        labelKey: 'incomeType.fosterCareIncome',
    },
    [OtherIncomeTypes.EI_INDEMNITY_INCOME]: {
        id: OtherIncomeTypes.EI_INDEMNITY_INCOME,
        labelKey: 'incomeType.eiIndemnityIncome',
    },
    [OtherIncomeTypes.RRIF]: {
        id: OtherIncomeTypes.RRIF,
        labelKey: 'incomeType.rrif',
    },
    [OtherIncomeTypes.TRUST_INCOME]: {
        id: OtherIncomeTypes.TRUST_INCOME,
        labelKey: 'incomeType.trustIncome',
    },
    [OtherIncomeTypes.SHORT_TERM_DISABILITY]: {
        id: OtherIncomeTypes.SHORT_TERM_DISABILITY,
        labelKey: 'incomeType.shortTermDisability',
    },
    [OtherIncomeTypes.RENTAL_INCOME_SURPLUS]: {
        id: OtherIncomeTypes.RENTAL_INCOME_SURPLUS,
        labelKey: 'incomeType.rentalIncomeSurplus',
    },
    [OtherIncomeTypes.OTHER]: {
        id: OtherIncomeTypes.OTHER,
        labelKey: 'other',
    },
};

export const useOtherIncomeOptions = () => {
    const { i18n } = useI18n();

    const {
        typesOverride: {
            settings: { otherIncomeTypes },
        },
    } = useTenantSettings();

    return otherIncomeTypes.map((typeId) => ({
        value: typeId,
        label: i18n._(OTHER_INCOME_MAP[typeId].labelKey),
    }));
};
