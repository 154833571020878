import { LiabilityTypes, useTenantSettings } from '@nestoca/multi-tenant';

import { useI18n } from 'providers/i18n/use-i18n';

type Liabilities = typeof LiabilityTypes;

type LiabilityValue = Liabilities[keyof Liabilities];

type LiabilitiesMap = Record<
    LiabilityValue,
    {
        id: LiabilityValue;
        labelKey: string;
    }
>;

export const LIABILITY_TYPE_MAP: LiabilitiesMap = {
    [LiabilityTypes.CREDIT_CARD]: {
        id: LiabilityTypes.CREDIT_CARD,
        labelKey: 'CREDIT_CARD',
    },
    [LiabilityTypes.PERSONAL_LOAN]: {
        id: LiabilityTypes.PERSONAL_LOAN,
        labelKey: 'PERSONAL_LOAN',
    },
    [LiabilityTypes.STUDENT_LOAN]: {
        id: LiabilityTypes.STUDENT_LOAN,
        labelKey: 'STUDENT_LOAN',
    },
    [LiabilityTypes.AUTO_LOAN]: {
        id: LiabilityTypes.AUTO_LOAN,
        labelKey: 'AUTO_LOAN',
    },
    [LiabilityTypes.AUTO_LEASE]: {
        id: LiabilityTypes.AUTO_LEASE,
        labelKey: 'AUTO_LEASE',
    },
    [LiabilityTypes.SECURED_LINE_OF_CREDIT]: {
        id: LiabilityTypes.SECURED_LINE_OF_CREDIT,
        labelKey: 'SECURED_LINE_OF_CREDIT',
    },
    [LiabilityTypes.UNSECURED_LINE_OF_CREDIT]: {
        id: LiabilityTypes.UNSECURED_LINE_OF_CREDIT,
        labelKey: 'UNSECURED_LINE_OF_CREDIT',
    },
    [LiabilityTypes.ALIMONY]: {
        id: LiabilityTypes.ALIMONY,
        labelKey: 'ALIMONY',
    },
    [LiabilityTypes.CHILD_SUPPORT]: {
        id: LiabilityTypes.CHILD_SUPPORT,
        labelKey: 'CHILD_SUPPORT',
    },
    [LiabilityTypes.INCOME_TAX]: {
        id: LiabilityTypes.INCOME_TAX,
        labelKey: 'INCOME_TAX',
    },
    [LiabilityTypes.LEASE]: {
        id: LiabilityTypes.LEASE,
        labelKey: 'LEASE',
    },
    [LiabilityTypes.WAGE_GARNISHMENT]: {
        id: LiabilityTypes.WAGE_GARNISHMENT,
        labelKey: 'WAGE_GARNISHMENT',
    },
    [LiabilityTypes.RENT]: {
        id: LiabilityTypes.RENT,
        labelKey: 'RENT',
    },
    [LiabilityTypes.CLOSING_COSTS]: {
        id: LiabilityTypes.CLOSING_COSTS,
        labelKey: 'CLOSING_COSTS',
    },
    [LiabilityTypes.RENTAL_SHORTAGE]: {
        id: LiabilityTypes.RENTAL_SHORTAGE,
        labelKey: 'RENTAL_SHORTAGE',
    },
    [LiabilityTypes.MORTGAGE]: {
        id: LiabilityTypes.MORTGAGE,
        labelKey: 'MORTGAGE',
    },
    [LiabilityTypes.BUREAU_ADJUSTMENT]: {
        id: LiabilityTypes.BUREAU_ADJUSTMENT,
        labelKey: 'BUREAU_ADJUSTMENT',
    },
    [LiabilityTypes.COLLECTION]: {
        id: LiabilityTypes.COLLECTION,
        labelKey: 'COLLECTION',
    },
    [LiabilityTypes.JUDGEMENT]: {
        id: LiabilityTypes.JUDGEMENT,
        labelKey: 'JUDGEMENT',
    },
    [LiabilityTypes.CURRENT_TENANT_MORTGAGE]: {
        id: LiabilityTypes.CURRENT_TENANT_MORTGAGE,
        labelKey: 'CURRENT_TENANT_MORTGAGE',
    },
    [LiabilityTypes.OTHER]: {
        id: LiabilityTypes.OTHER,
        labelKey: `Other`,
    },
};

export const useLiabilityOptions = () => {
    const { i18n } = useI18n();

    const {
        typesOverride: {
            settings: { liabilityTypes },
        },
    } = useTenantSettings();

    return liabilityTypes.map((typeId) => ({
        value: typeId,
        label: i18n._(LIABILITY_TYPE_MAP[typeId].labelKey),
    }));
};
