import React from 'react';

import { useTenantSetting } from '@nestoca/multi-tenant';
import { Grid } from '@nestoca/ui';

import { EditableCell } from 'components/editable';
import {
    CONSTRUCTION_TYPE_OPTIONS,
    AFTER_TRANSACTION_PURPOSE_LABEL,
} from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import { SectionDetailsProps } from 'types/submission-notes';
import { formatAddress } from 'utils';

import styles from './section-details.module.scss';

export const SubjectProperty = ({ submissionNotes }: SectionDetailsProps) => {
    const { i18n } = useI18n();

    const { address, occupancy, constructionType, existingMortgageNumber } =
        submissionNotes?.applicationFields?.subjectProperty || {};

    const { value: isExistingMortgageNumberEnabled } = useTenantSetting(
        'existingMortgageNumberEnabled'
    );

    return (
        <Grid className={styles.grid}>
            <EditableCell
                name="address"
                fieldType="readonly"
                label="address"
                value={formatAddress(address)}
            />
            {isExistingMortgageNumberEnabled && (
                <EditableCell
                    name="existingMortgageNumber"
                    fieldType="readonly"
                    label="existingMortgageNumber"
                    value={existingMortgageNumber}
                />
            )}
            <EditableCell
                name="purpose"
                fieldType="readonly"
                label="Occupancy"
                value={
                    occupancy
                        ? AFTER_TRANSACTION_PURPOSE_LABEL(i18n)[occupancy]
                        : undefined
                }
            />
            <EditableCell
                name="city"
                fieldType="readonly"
                label="City"
                value={address?.city}
            />
            <EditableCell
                name="stateCode"
                fieldType="readonly"
                label="stateCode"
                value={address?.stateCode}
            />
            <EditableCell
                name="postalCode"
                fieldType="readonly"
                label="postalCode"
                value={address?.postalCode}
            />
            <EditableCell
                name="constructionType"
                fieldType="readonly"
                label="constructionType"
                options={CONSTRUCTION_TYPE_OPTIONS(i18n)}
                value={constructionType}
            />
        </Grid>
    );
};
