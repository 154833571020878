import React from 'react';

import { useRecoilValue } from 'recoil';

import { CellData } from 'components/dashboard/applicants/components/cell-data';
import { EditableCell } from 'components/editable';
import { useOtherIncomeOptions } from 'constants/other-income';
import { getApplicantsSelectOptions } from 'store/applications';
import { IncomeOther } from 'types/applicant';

import styles from './other-income.module.scss';

type Props = {
    isEditing: boolean;
    applicationId: number;
    applicantId: number;
    otherIncome: IncomeOther;
    isDescriptionRequired?: boolean;
    isDescriptionEditable?: boolean;
};

export const OtherIncomeFieldsCreation = ({
    isEditing,
    applicationId,
    applicantId,
    otherIncome,
    isDescriptionRequired,
    isDescriptionEditable,
}: Props) => {
    const applicantOptions = useRecoilValue(
        getApplicantsSelectOptions(applicationId)
    );

    const otherIncomeOptions = useOtherIncomeOptions();

    return (
        <>
            <EditableCell
                className={styles['applicant-field']}
                required
                isEditing={isEditing}
                name="applicantId"
                label="applicant"
                fieldType="select"
                options={applicantOptions}
                value={applicantId}
            />
            <EditableCell
                className={styles['type-field']}
                required
                isEditing={isEditing}
                name="type"
                label="type"
                fieldType="select"
                options={otherIncomeOptions}
                value={otherIncome.type}
            />
            {isDescriptionEditable ? (
                <EditableCell
                    className={styles['description-field']}
                    required={isDescriptionRequired}
                    isEditing={isEditing}
                    name="description"
                    label="description"
                    value={otherIncome?.description}
                />
            ) : (
                <CellData
                    className={styles['description-field']}
                    tx="description"
                    value="-"
                />
            )}
        </>
    );
};
