import { Suspense } from 'react';

import { Trans } from '@lingui/react';
import { Box, Button, Card, Flex, Grid, Typography } from '@nestoca/ui';
import { BsHouseDoor } from 'react-icons/bs';
import Skeleton from 'react-loading-skeleton';
import { useRecoilValue } from 'recoil';

import { CellData } from 'components/dashboard/applicants/components/cell-data';
import { MainApplicationTypeLabel } from 'components/dashboard-header/dashboard-header';
import { LENDER } from 'constants/appConstants';
import { PROPERTY_PURPOSE_MAP } from 'constants/property';
import { useI18n } from 'providers/i18n/use-i18n';
import { useModal } from 'providers/modals/use-modal';
import { getPropertySelectors, selectedApplication } from 'store/applications';
import { formatAddress } from 'utils';
import { getApplicationMainType } from 'utils/application-type';
import { formatDate } from 'utils/property';

import dashBoardStyles from '../broker-dashboard.module.scss';

import styles from './property-card.module.scss';

import type { PropertyModalProps } from 'components/dashboard/broker-dashboard-new/property-card/property-modal';

export const PropertyDefaultFields = ({
    showViewMore = false,
}: {
    showViewMore?: boolean;
}) => {
    const { i18n } = useI18n();

    const application = useRecoilValue(selectedApplication);

    const { open: openPropertyModal } = useModal<PropertyModalProps>(
        'dashBoardPropertyModal'
    );

    if (!application) {
        return null;
    }

    const { property, type } = application;

    const { mainType, isNewApplication } = getApplicationMainType(type);

    return (
        <>
            <Box>
                <Flex className={dashBoardStyles.header} align="center" gap={2}>
                    <BsHouseDoor size={30} />
                    {showViewMore ? (
                        <Flex className={styles.viewMore}>
                            <Typography weight={7} size={1}>
                                {i18n._('asset.property')}
                            </Typography>
                            {showViewMore && (
                                <Button
                                    variant="ghost"
                                    onClick={() => openPropertyModal()}
                                    size="small"
                                >
                                    <Trans id="viewMore" />
                                </Button>
                            )}
                        </Flex>
                    ) : (
                        <Grid>
                            <Typography size={0}>
                                {i18n._('asset.property')}
                            </Typography>
                            <Typography weight={7} size={0}>
                                {MainApplicationTypeLabel(i18n)[mainType]}
                            </Typography>
                        </Grid>
                    )}
                </Flex>
            </Box>
            <Grid className={styles.grid}>
                <CellData
                    tx={'dashboardHeader.propertyPurpose'}
                    value={
                        property?.purpose
                            ? i18n._(
                                  PROPERTY_PURPOSE_MAP[property.purpose]
                                      .labelKey
                              )
                            : undefined
                    }
                />
                {!isNewApplication && (
                    <>
                        <EquityContainer />
                        <CellData
                            tx={'lender'}
                            value={LENDER(i18n)[property?.mortgages[0]?.lender]}
                        />

                        <CellData
                            tx={'maturityDate'}
                            value={formatDate(
                                property?.mortgages[0]?.maturityDate,
                                i18n.locale
                            )}
                        />
                    </>
                )}
                <CellData
                    tx={'address'}
                    value={formatAddress(property?.address)}
                />
            </Grid>
        </>
    );
};

export const PropertyCardContainer = () => (
    <Card className={dashBoardStyles.card} title={'asset.property'}>
        <PropertyDefaultFields showViewMore />
    </Card>
);

const EquityView = () => {
    const { equity } = useRecoilValue(getPropertySelectors);

    return <CellData fieldType="money" tx={'equity'} value={equity} />;
};

const EquityContainer = () => (
    <Suspense fallback={<Skeleton />}>
        <EquityView />
    </Suspense>
);
