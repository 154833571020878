import { useRecoilValue } from 'recoil';

import {
    INITIAL_REVIEW_CATEGORIES,
    SECOND_REVIEW_CATEGORIES,
} from 'constants/appConstants';
import {
    applicationDocumentsCounts,
    getDocumentsGrouped,
    getSelectedDocumentStates,
    getSelectedDocumentType,
} from 'store/documents';
import { UnderwriterChecklistState } from 'store/underwriter-flow';
import { UnderwriterChecklistResponse } from 'types/checklist';
import { ApplicantDocument, DocumentStep } from 'types/documents';
import { UnderwriterChecklistItem } from 'types/qualification';

type Counts = {
    [name: string]: {
        total: number;
        approved: number;
    };
};

export const getCompleteDocumentsCount = (
    data: UnderwriterChecklistItem[][]
) => {
    return data.reduce(
        (acc, value) => {
            acc.total += value.length;
            value.map((document) => {
                if (document.checked) {
                    acc.current += 1;
                }
            });
            return acc;
        },
        { total: 0, current: 0 }
    );
};

export const getLabel = (value: boolean | null) => {
    if (value === null) {
        return 'PENDING';
    }
    return value ? 'APPROVED' : 'REJECTED';
};

export const getVariant = (value: 'PENDING' | 'APPROVED' | 'REJECTED') => {
    if (value === 'PENDING') {
        return 'default';
    }
    return value === 'APPROVED' ? 'success' : 'error';
};

export const getDocsCountsBySections = (documents: ApplicantDocument[]) => {
    let result = {} as Counts;
    documents.forEach((document) => {
        const isDocumentApproved =
            document.state === 'DOCUMENT_BROKER_APPROVED';
        if (result.hasOwnProperty(document.category)) {
            result[document.category].total += 1;
            isDocumentApproved
                ? (result[document.category].approved += 1)
                : null;
        } else {
            result[document.category] = {
                total: 1,
                approved: isDocumentApproved ? 1 : 0,
            };
        }
    });
    return result;
};

export const useApplicantsDocumentsBySection = ({
    currentApplicationId,
    applicant,
}) => {
    const documentsCount = useRecoilValue(
        applicationDocumentsCounts({
            applicationId: currentApplicationId,
            step: DocumentStep.All,
        })
    );
    const allDocsReceived =
        documentsCount.countsStateByApplicantId[applicant.applicantId]
            .allReceived;

    const docStates = useRecoilValue(
        getSelectedDocumentStates({ applicationId: currentApplicationId })
    );
    const docStep = useRecoilValue(
        getSelectedDocumentType({ applicationId: currentApplicationId })
    );
    const documents = useRecoilValue(
        getDocumentsGrouped({
            applicationId: currentApplicationId,
            applicantId: applicant.applicantId,
            docStates,
            step: docStep,
        })
    );

    return { allDocsReceived, documents };
};

export const categorizeUnderwriterChecklistItems = (
    initialReviewChecklist: UnderwriterChecklistResponse[]
) => {
    const sections = new Map<
        UnderwriterChecklistState,
        UnderwriterChecklistResponse[]
    >();

    initialReviewChecklist.forEach((item) => {
        let categoryItems = sections.get(item.category);
        if (!categoryItems) {
            categoryItems = [];
            sections.set(item.category, categoryItems);
        }
        categoryItems.push(item);
    });

    return sections;
};

export const orderSectionsByCategory = (
    filteredSections: UnderwriterChecklistResponse[][],
    categories: UnderwriterChecklistState[]
) => {
    return categories.map((category) => {
        return filteredSections.find(
            (section) => section[0].category === category
        );
    });
};

export type ReviewChecklistType = 'INITIAL_REVIEW' | 'SECOND_REVIEW';

export const categorizeReviewChecklist = (
    initialReviewChecklist: UnderwriterChecklistResponse[],
    type: ReviewChecklistType
) => {
    const categories =
        type === 'INITIAL_REVIEW'
            ? INITIAL_REVIEW_CATEGORIES
            : SECOND_REVIEW_CATEGORIES;
    const sections = categorizeUnderwriterChecklistItems(
        initialReviewChecklist
    );
    const sectionValues = Array.from(sections.values());
    const orderedSections = orderSectionsByCategory(sectionValues, categories);
    const orderedSectionsWithoutEmpty = orderedSections.filter(
        (section) => section !== undefined
    );

    return orderedSectionsWithoutEmpty;
};

export const filterAndSortChecklist = (
    checklist: UnderwriterChecklistResponse[],
    type: ReviewChecklistType
) => {
    const categories =
        type === 'INITIAL_REVIEW'
            ? INITIAL_REVIEW_CATEGORIES
            : SECOND_REVIEW_CATEGORIES;
    const data = checklist
        .filter((item) => categories.includes(item.category))
        .sort((a, b) => a.code.localeCompare(b.code));
    return data || [];
};
