import React, { useEffect, useRef } from 'react';

import { Box } from '@nestoca/ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useFormContext } from 'react-hook-form';
import { SelectInstance } from 'react-select';
import { constSelector, useRecoilValue } from 'recoil';

import { AddressAutocomplete } from 'components/address-autocomplete';
import { EditableCell } from 'components/editable';
import { useAddressSituationOptions } from 'constants/address-situation';
import { RENTING_FREQUENCY_OPTIONS } from 'constants/appConstants';
import {
    US_STATES_OPTIONS,
    ALL_COUNTRIES_OPTIONS,
    PROVINCES_OPTIONS,
} from 'constants/contries-states';
import { ProblemType } from 'constants/problem';
import { RetrieveItem } from 'libs/canadaPost';
import { useI18n } from 'providers/i18n/use-i18n';
import {
    getIsMainApplicant,
    getMainApplicantCurrentAddress,
    getProblemsBySection,
} from 'store/applications';
import { RegisteredAddress } from 'types/address';
import {
    emptyApplicantAddressFields,
    hasSameAddressAsMainApplicant,
} from 'utils';
import { useProblems } from 'utils/use-problems';
import { compareForId } from 'utils/validations/comparators';

import styles from './register-address-modal.module.scss';

type Props = {
    isEditing: boolean;
    applicationId: number;
    applicantId?: number;
    index: number;
    registeredAddress: RegisteredAddress;
    formattedAddress: string;
    copyMainApplicantAddressOptionAvailable?: boolean;
};

export const RegisterAddressFields = ({
    isEditing,
    applicationId,
    applicantId,
    index,
    registeredAddress,
    formattedAddress,
    copyMainApplicantAddressOptionAvailable = false,
}: Props) => {
    const { i18n } = useI18n();

    const { showValidationProblems } = useFlags();

    const { watch, setValue } = useFormContext() || {};

    const autoCompleteRef = useRef<SelectInstance>(null);

    const isMainApplicant = useRecoilValue(
        applicantId
            ? getIsMainApplicant({ applicationId, applicantId })
            : constSelector(false)
    );

    const mainApplicantCurrentAddress = useRecoilValue(
        getMainApplicantCurrentAddress(applicationId)
    );

    const problems = useRecoilValue(
        getProblemsBySection({
            applicationId,
            section: ProblemType.registeredAddresses,
        })
    );

    let address = registeredAddress.address;
    let situation = registeredAddress.situation;
    let countryCode = registeredAddress.address.countryCode;

    if (watch) {
        address = watch('address');
        situation = watch('situation');
        countryCode = watch('address.countryCode');
    }

    const isRenting = situation === 'RENTING';

    const isUseMainApplicantAddressOptionVisible =
        isEditing &&
        !isMainApplicant &&
        copyMainApplicantAddressOptionAvailable;

    const sameAddressAsMainApplicant = hasSameAddressAsMainApplicant(
        address,
        mainApplicantCurrentAddress
    );

    useProblems(
        showValidationProblems ? problems : [],
        applicantId,
        registeredAddress
    );

    /* Update the "useMainApplicantAddress" checkbox status when the main
       applicant address changes */
    useEffect(() => {
        if (!isEditing) return;

        setValue('useMainApplicantAddress', sameAddressAsMainApplicant);
    }, [isEditing, sameAddressAsMainApplicant, setValue]);

    /* Update the applicant address fields when the "useMainApplicantAddress"
       checkbox is being changed */
    const handleUseMainApplicantAddressChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const checked = e.target.checked;

        autoCompleteRef.current?.clearValue();

        if (checked) {
            setValue('address', mainApplicantCurrentAddress);
        } else {
            emptyApplicantAddressFields(setValue);
        }
    };

    const handleOnRetrieve = (items: RetrieveItem[]) => {
        const selectedAddress = items?.[0];

        setValue('address.countryCode', selectedAddress.CountryIso2);
        setValue('address.city', selectedAddress.City);
        setValue('address.postalCode', selectedAddress.PostalCode);
        setValue('address.stateCode', selectedAddress.ProvinceCode);
        setValue('address.unit', selectedAddress.SubBuilding);
        setValue('address.streetNumber', selectedAddress.BuildingNumber);
        setValue('address.street', selectedAddress.Street);
    };

    const ADDRESS_SITUATION_OPTIONS = useAddressSituationOptions();

    return (
        <>
            {isUseMainApplicantAddressOptionVisible && (
                <EditableCell
                    className={styles['useMainApplicantAddressCheckbox']}
                    isEditing={isEditing}
                    name="useMainApplicantAddress"
                    label="useMainApplicantAddress"
                    fieldType="checkbox"
                    onChange={handleUseMainApplicantAddressChange}
                    value={sameAddressAsMainApplicant}
                    squareCheckbox
                    style={{
                        gridColumn: '1 / -1',
                        fontWeight: 'var(--font-weigth-4)',
                    }}
                />
            )}
            <EditableCell
                isEditing={isEditing}
                name="isCurrentAddress"
                fieldType="boolean"
                label="isCurrentAddress"
                value={registeredAddress.isCurrentAddress}
                required
                isFieldInvalidCompareFn={compareForId(registeredAddress)}
                problemType={ProblemType.registeredAddresses}
            />
            <EditableCell
                isEditing={isEditing}
                name="occupiedYears"
                fieldType="number"
                label="occupiedYears"
                value={registeredAddress.occupiedYears}
                min={0}
                max={90}
                integer
                required
                isFieldInvalidCompareFn={compareForId(registeredAddress)}
                problemType={ProblemType.registeredAddresses}
            />
            <EditableCell
                isEditing={isEditing}
                name="occupiedMonths"
                fieldType="number"
                label="occupiedMonths"
                value={registeredAddress.occupiedMonths}
                min={0}
                max={12}
                integer
                required
                isFieldInvalidCompareFn={compareForId(registeredAddress)}
                problemType={ProblemType.registeredAddresses}
            />
            <EditableCell
                isEditing={isEditing}
                name="situation"
                fieldType="select"
                options={ADDRESS_SITUATION_OPTIONS}
                label="addressSituation"
                value={registeredAddress.situation}
                required
                isFieldInvalidCompareFn={compareForId(registeredAddress)}
                problemType={ProblemType.registeredAddresses}
            />
            {isRenting && (
                <>
                    <EditableCell
                        isEditing={isEditing}
                        name="rent.amount"
                        fieldType="money"
                        label="amount"
                        value={registeredAddress.rent?.amount}
                        required
                        isFieldInvalidCompareFn={compareForId(
                            registeredAddress
                        )}
                        problemType={ProblemType.registeredAddresses}
                    />
                    <EditableCell
                        isEditing={isEditing}
                        name="rent.frequency"
                        fieldType="select"
                        options={RENTING_FREQUENCY_OPTIONS(i18n)}
                        label="frequency"
                        value={registeredAddress.rent?.frequency}
                        required
                        isFieldInvalidCompareFn={compareForId(
                            registeredAddress
                        )}
                        problemType={ProblemType.registeredAddresses}
                    />
                </>
            )}
            {!isEditing && (
                <EditableCell
                    isEditing={isEditing}
                    name="address.formattedAddress"
                    fieldType="readonly"
                    label={index ? 'address' : 'currentAddress'}
                    value={formattedAddress}
                    isFieldInvalidCompareFn={compareForId(registeredAddress)}
                    problemType={ProblemType.registeredAddresses}
                    style={{ gridColumn: '1 / -1' }}
                />
            )}
            {isEditing && (
                <>
                    <Box style={{ gridColumn: '1 / -1' }}>
                        <AddressAutocomplete
                            ref={autoCompleteRef}
                            onRetrieve={handleOnRetrieve}
                            country={registeredAddress.address.countryCode}
                        />
                    </Box>
                    <EditableCell
                        isEditing={isEditing}
                        name="address.countryCode"
                        fieldType="select"
                        label="Country"
                        value={registeredAddress.address.countryCode}
                        options={ALL_COUNTRIES_OPTIONS(i18n)}
                        required
                        isFieldInvalidCompareFn={compareForId(
                            registeredAddress
                        )}
                        problemType={ProblemType.registeredAddresses}
                    />
                    <EditableCell
                        isEditing={isEditing}
                        name="address.city"
                        label="City"
                        value={registeredAddress.address.city}
                        required
                        isFieldInvalidCompareFn={compareForId(
                            registeredAddress
                        )}
                        problemType={ProblemType.registeredAddresses}
                    />
                    <EditableCell
                        isEditing={isEditing}
                        name="address.postalCode"
                        fieldType="postalCode"
                        label={countryCode === 'CA' ? 'postalCode' : 'zipCode'}
                        required={countryCode === 'CA' || countryCode === 'US'}
                        countryCode={registeredAddress.address.countryCode}
                        value={registeredAddress.address.postalCode}
                        isFieldInvalidCompareFn={compareForId(
                            registeredAddress
                        )}
                        problemType={ProblemType.registeredAddresses}
                    />
                    {['CA', 'US'].includes(countryCode) && (
                        <EditableCell
                            isEditing={isEditing}
                            name="address.stateCode"
                            fieldType="select"
                            label={countryCode === 'CA' ? 'stateCode' : 'state'}
                            value={registeredAddress.address.stateCode}
                            options={
                                countryCode === 'US'
                                    ? US_STATES_OPTIONS(i18n)
                                    : PROVINCES_OPTIONS(i18n)
                            }
                            required
                            isFieldInvalidCompareFn={compareForId(
                                registeredAddress
                            )}
                            problemType={ProblemType.registeredAddresses}
                        />
                    )}
                    <EditableCell
                        isEditing={isEditing}
                        name="address.unit"
                        label="Unit"
                        value={registeredAddress.address.unit}
                        isFieldInvalidCompareFn={compareForId(
                            registeredAddress
                        )}
                        problemType={ProblemType.registeredAddresses}
                    />
                    <EditableCell
                        isEditing={isEditing}
                        name="address.streetNumber"
                        label="Street Number"
                        value={registeredAddress.address.streetNumber}
                        required
                        isFieldInvalidCompareFn={compareForId(
                            registeredAddress
                        )}
                        problemType={ProblemType.registeredAddresses}
                    />
                    <EditableCell
                        isEditing={isEditing}
                        name="address.street"
                        label="Street Name"
                        value={registeredAddress.address.street}
                        required
                        isFieldInvalidCompareFn={compareForId(
                            registeredAddress
                        )}
                        problemType={ProblemType.registeredAddresses}
                    />
                </>
            )}
        </>
    );
};
