import React from 'react';

import { Flex, Grid, Typography } from '@nestoca/ui';
import cn from 'classnames';

import { EditableCell } from 'components/editable';
import {
    LIABILITY_CREDIT_TYPE,
    LIABILITY_CURRENCY_OPTIONS,
} from 'constants/appConstants';
import { useLiabilityOptions } from 'constants/liabilities';
import { useI18n } from 'providers/i18n/use-i18n';
import { SelectOption } from 'types';
import { Liability } from 'types/applicant';
import { compareForId } from 'utils/validations/comparators';

import styles from './section.module.scss';

type Props = {
    isCreating: boolean;
    isEditing: boolean;
    coOwnerApplicantIds: number[];
    coOwnerApplicantOptions: SelectOption<number>[];
    liability: Liability;
    partialSchema: boolean;
};

export const Details = ({
    isCreating,
    isEditing,
    coOwnerApplicantIds,
    coOwnerApplicantOptions,
    liability,
    partialSchema,
}: Props) => {
    const { i18n } = useI18n();

    const { type, highCredit, balance, currency, description, creditType } =
        liability;

    const liabilityTypeOptions = useLiabilityOptions();

    return (
        <Flex
            className={cn(styles.section, styles['section--details'], {
                [styles['section--modal']]: isCreating,
            })}
            direction="column"
            gap={4}
        >
            <Typography weight={7} textColor="var(--color-black)">
                {i18n._('details')}
            </Typography>
            <Grid
                className={cn(styles.grid, {
                    [styles['grid--auto-fit']]: isCreating,
                })}
                gap={4}
            >
                <EditableCell
                    className={styles.type}
                    required={!partialSchema}
                    isEditing={isEditing}
                    name="type"
                    label="type"
                    fieldType="select"
                    options={liabilityTypeOptions}
                    value={type}
                    isFieldInvalidCompareFn={compareForId(liability)}
                />
                <EditableCell
                    isMulti
                    isClearable
                    isEditing={isEditing}
                    name="coOwnerApplicantIds"
                    label="coOwnerApplicantIds"
                    fieldType="select"
                    options={coOwnerApplicantOptions}
                    value={coOwnerApplicantIds}
                    isFieldInvalidCompareFn={compareForId(liability)}
                />
                <EditableCell
                    required={!partialSchema}
                    isEditing={isEditing}
                    name="highCredit"
                    fieldType="money"
                    label="highCredit"
                    value={highCredit}
                    isFieldInvalidCompareFn={compareForId(liability)}
                />
                <EditableCell
                    required={!partialSchema}
                    isEditing={isEditing}
                    name="balance"
                    label="balance"
                    fieldType="money"
                    value={balance}
                    isFieldInvalidCompareFn={compareForId(liability)}
                />
                <EditableCell
                    required={!partialSchema}
                    isEditing={isEditing}
                    isClearable={partialSchema}
                    name="currency"
                    label="currency"
                    fieldType="select"
                    options={LIABILITY_CURRENCY_OPTIONS(i18n)}
                    value={currency}
                    isFieldInvalidCompareFn={compareForId(liability)}
                />
                <EditableCell
                    required
                    isEditing={isEditing}
                    name="creditType"
                    label="creditType"
                    fieldType={isCreating ? 'select' : 'readonly'}
                    options={LIABILITY_CREDIT_TYPE(i18n)}
                    value={creditType}
                    isFieldInvalidCompareFn={compareForId(liability)}
                />
            </Grid>
            <EditableCell
                isEditing={isEditing}
                name="description"
                fieldType="textarea"
                label="description"
                value={description}
                isFieldInvalidCompareFn={compareForId(liability)}
            />
        </Flex>
    );
};
