import React, { Fragment } from 'react';

import { Grid } from '@nestoca/ui';
import { useRecoilValue } from 'recoil';

import { EditableCell } from 'components/editable';
import { AFTER_TRANSACTION_PURPOSE_LABEL } from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import { getApplicantIdSelected } from 'store/applications';
import { SectionDetailsProps } from 'types/submission-notes';

import { Comments } from './comments';
import { EmptySection } from './empty-section';
import styles from './section-details.module.scss';

export const OwnedProperties = ({
    sectionCompleted,
    title,
    submissionNotes,
}: SectionDetailsProps) => {
    const { i18n } = useI18n();
    const { applicationId } = submissionNotes;

    const { otherProperties } = submissionNotes?.customFields || {};
    const { OtherProperties: applicationOtherProperties } =
        submissionNotes?.applicationFields || {};

    const applicantId = useRecoilValue(getApplicantIdSelected(applicationId));

    /* Issue with the backend here they return the applicantId with the wrong property:
    applicationId instead of applicantId */
    const applicantProperties = Object.values(
        applicationOtherProperties
    ).filter(
        // @ts-ignore
        (property) => property.applicationId === applicantId
    );

    if (applicantProperties.length === 0) {
        return (
            <EmptySection
                applicationId={submissionNotes.applicationId}
                title={title}
            />
        );
    }

    return (
        <Grid className={styles.grid}>
            {Object.keys(applicationOtherProperties).map((key) => {
                const { id, address, afterTransactionPurpose, portProperty } =
                    applicationOtherProperties[key] || {};

                const comments = otherProperties[key]?.comments;

                return (
                    <Fragment key={id}>
                        <EditableCell
                            name="address"
                            fieldType="readonly"
                            label="address"
                            value={address}
                        />
                        <EditableCell
                            name="afterTransactionPurpose"
                            fieldType="readonly"
                            label="afterTransactionPurpose"
                            value={
                                AFTER_TRANSACTION_PURPOSE_LABEL(i18n)[
                                    afterTransactionPurpose
                                ]
                            }
                        />
                        {portProperty && (
                            <>
                                <EditableCell
                                    name="mortgageBalance"
                                    fieldType="money"
                                    label="mortgageBalance"
                                    value={portProperty.currentPropertyAmount}
                                />
                                <EditableCell
                                    name="propertyRate"
                                    fieldType="readonly"
                                    label="interestRate"
                                    value={portProperty.currentPropertyRate}
                                />
                                <EditableCell
                                    name="mortgageTermDueDate"
                                    fieldType="date"
                                    label="mortgageTermDueDate"
                                    value={portProperty.currentPropertyTerm}
                                />
                                <EditableCell
                                    name="expectToBeSoldDate"
                                    fieldType="date"
                                    label="expectToBeSoldDate"
                                    value={portProperty.expectedDateToBeSold}
                                />
                            </>
                        )}
                        <Comments
                            isEditing={!sectionCompleted}
                            name={`otherProperties[${id}].comments`}
                            label="comments"
                            value={comments}
                        />
                    </Fragment>
                );
            })}
        </Grid>
    );
};
