import { useCallback, useMemo } from 'react';

import { useTenantSetting } from '@nestoca/multi-tenant';
import {
    atomFamily,
    selectorFamily,
    useRecoilValue,
    useSetRecoilState,
} from 'recoil';

import { helocDetailsHasErrors } from 'components/application-review/section/heloc-details/utils';
import { mortgageDetailsHasErrors } from 'components/application-review/section/mortgage-details/utils';
import { assetsAndDownpaymentHasError } from 'components/asset/utils';
import {
    applicationHasExistingMortgageNumber,
    applicationHasPurchaseWithImprovementDiscrepancy,
} from 'components/subject-property/utils';
import {
    categorizeReviewChecklist,
    filterAndSortChecklist,
    ReviewChecklistType,
} from 'components/underwriter-flow/utils';
import { SectionsValues } from 'constants/appConstants';
import { client as apiClient } from 'libs/api';
import {
    getSumCurrentIncome,
    getSumApplicantOtherIncome,
    getHasHelocAmountDiscrepancy,
    getHasMortgageAmountDiscrepancy,
    getApplicationMortgage,
} from 'store/applications';
import { persistAtom } from 'store/persist-atom';
import { SectionError, SectionKeys } from 'types/application';
import {
    amountFrequencyTo,
    amountFrequencyWithRationTo,
    formatAddress,
} from 'utils';
import {
    applicantLiabilitySameFrequency,
    otherDebtObligationsSameFrequency,
    propertyLiabilitySameFrequency,
    qualificationSameFrequency,
} from 'utils/adapter/qualification';

import {
    getApplicantAddressHasEnoughHistory,
    getApplicantEmploymentHasEnoughHistory,
    getApplication,
    getApplicant,
    getPropertyById,
} from './applications';
import { getHelocApplicationState } from './heloc';
import { useRefreshRecoilState } from './use-refresh-recoil-state';

import type { Frequency } from 'types';
import type {
    ApplicantsAmountFrequency,
    ApplicantsAmountFrequencyWithRatio,
    Qualification,
} from 'types/qualification';

export const getSelectedApplicantsIds = selectorFamily({
    key: 'GetSelectedApplicantsIds',
    get:
        ({ applicationId }: { applicationId: number }) =>
        async ({ get }) => {
            const qualification = await get(
                getQualificationState(applicationId)
            );

            const selectedIds: number[] = [];
            for (const applicantId in qualification.applicants) {
                if (qualification.applicants[applicantId].included) {
                    selectedIds.push(+applicantId);
                }
            }

            return selectedIds;
        },
});

export const getQualificationApplicants = selectorFamily({
    key: 'getApplicantsIdsList',
    get:
        ({ applicationId }: { applicationId: number }) =>
        async ({ get }) => {
            const qualification = await get(
                getQualificationState(applicationId)
            );

            return qualification.applicants;
        },
});

export const getSelectedApplicantsDetails = selectorFamily({
    key: 'getSelectedApplicantsDetails',
    get:
        ({ applicationId }: { applicationId: number }) =>
        ({ get }) => {
            const selectedApplicants = get(
                getSelectedApplicantsIds({ applicationId })
            );

            let totalIncome = 0;
            let totalOtherIncome = 0;

            (selectedApplicants || []).forEach((applicantId) => {
                const currentIncome = get(
                    getSumCurrentIncome({
                        applicationId,
                        applicantId,
                    })
                );

                const otherIncome = get(
                    getSumApplicantOtherIncome({
                        applicationId,
                        applicantId,
                    })
                );

                totalIncome += currentIncome;
                totalOtherIncome += otherIncome;
            });

            return {
                totalIncome,
                totalOtherIncome,
            };
        },
});

export const getQualificationValues = selectorFamily({
    key: 'getQualificationValues',
    get: (applicationId: number) => async () => {
        try {
            const { data } = await apiClient.getQualification(applicationId);

            return data;
        } catch (error) {
            console.error('Qualification values error', error);
            return {} as Qualification;
        }
    },
});

export const useRefreshQualification = () => {
    const qualificationsFetcher = async (applicationId: number) => {
        const { data } = await apiClient.getQualification(applicationId);
        return data;
    };

    return useRefreshRecoilState<number, Qualification>({
        recoilStateGetter: getQualificationState,
        fetcher: qualificationsFetcher,
        validator: (applicationId) => !!applicationId,
    });
};

export const getUnderwriterChecklist = selectorFamily({
    key: 'getUnderwriterChecklist',
    get: (applicationId: number) => async () => {
        try {
            const { data } =
                await apiClient.getUnderwriterChecklist(applicationId);

            return data;
        } catch (error) {
            console.error('Underwriter checklist GET error', error);
            return [];
        }
    },
});

export const getUnderwriterChecklistState = atomFamily({
    key: 'getUnderwriterChecklistState',
    default: getUnderwriterChecklist,
});

export const useRefreshUnderwriterChecklist = (applicationId: number) => {
    const setUnderwriterChecklist = useSetRecoilState(
        getUnderwriterChecklistState(applicationId)
    );

    return async () => {
        const { data } = await apiClient.getUnderwriterChecklist(applicationId);
        setUnderwriterChecklist(data);

        return data;
    };
};

export const useGetUnderwriterReviewSectionLists = (applicationId: number) => {
    const checklistState = useRecoilValue(
        getUnderwriterChecklistState(applicationId)
    );

    const { value: excludedUnderwriterChecklistItems = [] } = useTenantSetting(
        'excludedUnderwriterChecklistItems'
    );

    const filteredChecklistItems = useMemo(
        () =>
            checklistState.filter(
                (item) => !excludedUnderwriterChecklistItems.includes(item.code)
            ),
        [checklistState, excludedUnderwriterChecklistItems]
    );

    const createReviewList = useCallback(
        (type: ReviewChecklistType) =>
            categorizeReviewChecklist(
                filterAndSortChecklist(filteredChecklistItems, type),
                type
            ),
        [filteredChecklistItems]
    );

    return useMemo(
        () => ({
            initialReviewList: createReviewList('INITIAL_REVIEW'),
            secondReviewList: createReviewList('SECOND_REVIEW'),
        }),
        [createReviewList]
    );
};

export const getAssetsAndDownpaymentErrors = selectorFamily({
    key: 'getAssetsAndDownpaymentErrors',
    get:
        ({
            applicationId,
            locale,
        }: {
            applicationId: number;
            locale: string;
        }) =>
        async ({ get }): Promise<SectionError[]> => {
            const application = get(getApplication(applicationId));

            const qualificationsState = get(
                getQualificationState(applicationId)
            );

            const errors = assetsAndDownpaymentHasError({
                application,
                qualifications: qualificationsState,
                locale,
            });

            return errors || [];
        },
});

export const getHelocDetailsErrors = selectorFamily({
    key: 'getHelocDetailsErrors',
    get:
        ({
            applicationId,
            locale,
        }: {
            applicationId: number;
            locale: string;
        }) =>
        async ({ get }): Promise<SectionError[]> => {
            const application = get(getApplication(applicationId));

            const hasHeloc = application?.heloc;

            if (!application || !hasHeloc) {
                return [];
            }

            const heloc = get(getHelocApplicationState(applicationId));

            const qualification = get(getQualificationState(applicationId));

            const errors = helocDetailsHasErrors({
                qualification,
                heloc,
                locale,
            });

            return errors;
        },
});

export const getMortgageDetailsErrors = selectorFamily({
    key: 'getMortgageDetailsErrors',
    get:
        ({
            applicationId,
            locale,
        }: {
            applicationId: number;
            locale: string;
        }) =>
        async ({ get }): Promise<SectionError[]> => {
            const application = get(getApplication(applicationId));

            if (!application) {
                return [];
            }

            const mortgageDetails = get(getApplicationMortgage(applicationId));
            const qualification = get(getQualificationState(applicationId));

            const errors = mortgageDetailsHasErrors({
                mortgageDetails,
                qualification,
                locale,
            });

            return errors;
        },
});

/**
 * @deprecated
 * Replaced with get<SectionName>Errors functions above (e.g. `getSubjectPropertyErrors`)
 */
export const getInvalidSectionStates = selectorFamily({
    key: 'getInvalidSectionStates',
    get:
        ({
            applicationId,
            isExistingMortgageNumberEnabled,
        }: {
            applicationId: number;
            isExistingMortgageNumberEnabled: boolean;
        }) =>
        ({ get }) => {
            try {
                const application = get(getApplication(applicationId));
                const applicantId = application?.mainApplicantId;
                const hasEnoughEmploymentHistory = get(
                    getApplicantEmploymentHasEnoughHistory({
                        applicationId,
                        applicantId,
                    })
                );
                const hasEnoughAddressHistory = get(
                    getApplicantAddressHasEnoughHistory({
                        applicationId,
                        applicantId,
                    })
                );

                const hasExistingMortgageNumber =
                    applicationHasExistingMortgageNumber({
                        application,
                    });

                const applicationState = get(
                    getQualificationState(applicationId)
                );

                const hasPurchaseWithImprovementDiscrepancy =
                    applicationHasPurchaseWithImprovementDiscrepancy({
                        application,
                        qualification: applicationState,
                    });

                const hasHelocAmountDiscrepancy = get(
                    getHasHelocAmountDiscrepancy({ applicationId })
                );

                const hasMortgageAmountDiscrepancy = get(
                    getHasMortgageAmountDiscrepancy({ applicationId })
                );

                const sections: SectionKeys[] = [];
                (Object.keys(applicationState?.sectionStates) || []).forEach(
                    (sectionState) => {
                        if (
                            applicationState?.sectionStates[sectionState]
                                .invalid
                        ) {
                            sections.push(SectionsValues[sectionState]);
                        }
                    }
                );

                if (!hasEnoughEmploymentHistory)
                    sections.push(SectionKeys.employments);
                if (!hasEnoughAddressHistory)
                    sections.push(SectionKeys.applicants);
                if (
                    (isExistingMortgageNumberEnabled &&
                        !hasExistingMortgageNumber) ||
                    hasPurchaseWithImprovementDiscrepancy
                )
                    sections.push(SectionKeys.subjectProperty);
                if (hasHelocAmountDiscrepancy)
                    sections.push(SectionKeys.helocDetails);
                if (hasMortgageAmountDiscrepancy)
                    sections.push(SectionKeys.mortgageDetails);

                return sections;
            } catch (error) {
                console.error('Section states error  error', error);
                return [];
            }
        },
});

export const getQualificationFrequency = atomFamily<
    'ANNUALLY' | 'MONTHLY',
    number
>({
    key: 'GetQualificationFrequency',
    default: 'ANNUALLY',
});

export const getQualification = selectorFamily({
    key: 'GetQualification',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const frequency = get(getQualificationFrequency(applicationId));
            const qualification = get(getQualificationState(applicationId));

            return qualificationSameFrequency(qualification, frequency);
        },
});

export const getHasCalculationExecutionPlan = selectorFamily({
    key: 'GetHascalCulationExecutionPlan',
    get:
        (applicationId: number) =>
        async ({ get }) => {
            const qualification = get(getQualificationState(applicationId));

            return Boolean(qualification.calculationExecutionPlan);
        },
});

export const getQualificationState = atomFamily({
    key: 'getQualificationState',
    default: getQualificationValues,
});

export const getQualificationDetailsOpen = atomFamily<boolean, number>({
    key: 'GetQualificationDetailOpen',
    default: true,
});

const sectionSelectedDefault = selectorFamily({
    key: 'SectionSelectedDefault',
    get:
        ({
            queryStringSection,
        }: {
            applicationId: number;
            queryStringSection?: SectionKeys;
        }) =>
        () => {
            if (Object.values(SectionKeys).includes(queryStringSection)) {
                return queryStringSection;
            }

            return SectionKeys.applicants;
        },
});

export const getCalculationApplicantsIncome = selectorFamily({
    key: 'getCalculationApplicantsIncome',
    get:
        ({ applicationId }: { applicationId: number }) =>
        ({ get }) => {
            const frequency = get(getQualificationFrequency(applicationId));
            const { calculationExecutionPlan } = get(
                getQualificationState(applicationId)
            );

            const applicantsIncome: ApplicantsAmountFrequency[] = [];

            (
                calculationExecutionPlan?.grossAnnualIncome
                    ?.applicantsTotalIncomes || []
            ).forEach((applicant) => {
                if (applicant.amount) {
                    const applicantInfo = get(
                        getApplicant({
                            applicationId,
                            applicantId: applicant.id,
                        })
                    );

                    applicantsIncome.push({
                        ...applicant,
                        ...amountFrequencyTo(
                            {
                                amount: applicant.amount,
                                frequency: applicant.frequency as Frequency,
                            },
                            frequency
                        ),
                        firstName: applicantInfo.firstName,
                        lastName: applicantInfo.lastName,
                    });
                }
            });

            return applicantsIncome;
        },
});

export const getApplicantsIncome = selectorFamily({
    key: 'GetApplicantsIncome',
    get:
        ({ applicationId }: { applicationId: number }) =>
        async ({ get }) => {
            const frequency = get(getQualificationFrequency(applicationId));
            const { applicantsIncome } = get(
                getQualificationState(applicationId)
            );

            return (
                applicantsIncome?.map((entity) => {
                    const applicant = get(
                        getApplicant({
                            applicationId,
                            applicantId: entity.applicantId,
                        })
                    );

                    return {
                        ...entity,
                        // Here's the catch. BE will return this with no frequency.
                        // value is always annual from BE.
                        // So we need to convert it to the frequency we want from 'ANNUALLY' hard coded.
                        employmentTotal:
                            amountFrequencyTo(
                                {
                                    amount: entity.employmentTotal,
                                    frequency: 'ANNUALLY',
                                },
                                frequency
                            )?.amount || 0,
                        otherTotal:
                            amountFrequencyTo(
                                {
                                    amount: entity.otherTotal,
                                    frequency: 'ANNUALLY',
                                },
                                frequency
                            )?.amount || 0,
                        firstName: applicant.firstName,
                        lastName: applicant.lastName,
                        frequency,
                    };
                }) || []
            );
        },
});

export const getCalculationRentalIncome = selectorFamily({
    key: 'getCalculationRentalIncome',
    get:
        ({ applicationId }: { applicationId: number }) =>
        ({ get }) => {
            const frequency = get(getQualificationFrequency(applicationId));
            const { calculationExecutionPlan } = get(
                getQualificationState(applicationId)
            );

            const applicantsRentalIncome: (ApplicantsAmountFrequencyWithRatio & {
                formattedAddress: string;
            })[] = [];

            (
                calculationExecutionPlan?.grossAnnualIncome
                    ?.otherPropertiesRentalIncomes || []
            ).forEach((ownedProperty) => {
                if (ownedProperty.amount) {
                    const propertyInfo = get(
                        getPropertyById({
                            applicationId,
                            propertyId: ownedProperty.id,
                        })
                    );

                    applicantsRentalIncome.push({
                        ...ownedProperty,
                        ...amountFrequencyWithRationTo(
                            {
                                amount: ownedProperty.amount,
                                ratioAmount: ownedProperty.ratioAmount,
                                frequency: ownedProperty.frequency as Frequency,
                            },
                            frequency
                        ),
                        formattedAddress: formatAddress(propertyInfo.address),
                    });
                }
            });

            return applicantsRentalIncome;
        },
});

export const getCalOtherPropertiesLiabilities = selectorFamily({
    key: 'GetCalOtherPropertiesLiabilities',
    get:
        ({ applicationId }: { applicationId: number }) =>
        ({ get }) => {
            const frequency = get(getQualificationFrequency(applicationId));
            const { calculationExecutionPlan } = get(
                getQualificationState(applicationId)
            );

            const otherPropertiesLiabilities =
                calculationExecutionPlan?.otherDebtObligations
                    ?.otherPropertiesLiabilities;

            return (
                otherPropertiesLiabilities?.map((property) =>
                    propertyLiabilitySameFrequency(property, frequency)
                ) || []
            );
        },
});

export const getCalApplicantsLiabilities = selectorFamily({
    key: 'GetCalApplicantsLiabilities',
    get:
        ({ applicationId }: { applicationId: number }) =>
        ({ get }) => {
            const frequency = get(getQualificationFrequency(applicationId));
            const { calculationExecutionPlan } = get(
                getQualificationState(applicationId)
            );

            const applicantsLiabilities =
                calculationExecutionPlan?.otherDebtObligations
                    ?.applicantsLiabilities || [];

            return (
                applicantsLiabilities?.map((applicantsLiability) =>
                    applicantLiabilitySameFrequency(
                        applicantsLiability,
                        frequency
                    )
                ) || []
            );
        },
});

export const getCalSubjectPropertyLiability = selectorFamily({
    key: 'GetCalSubjectPropertyLiability',
    get:
        ({ applicationId }: { applicationId: number }) =>
        ({ get }) => {
            const frequency = get(getQualificationFrequency(applicationId));
            const { calculationExecutionPlan } = get(
                getQualificationState(applicationId)
            );

            const subjectPropertyLiabilities =
                calculationExecutionPlan?.subjectPropertyLiabilities;

            return propertyLiabilitySameFrequency(
                subjectPropertyLiabilities,
                frequency
            );
        },
});

export const getCalOtherDebtObligations = selectorFamily({
    key: 'GetCalOtherDebtObligations',
    get:
        ({ applicationId }: { applicationId: number }) =>
        ({ get }) => {
            const frequency = get(getQualificationFrequency(applicationId));
            const { calculationExecutionPlan } = get(
                getQualificationState(applicationId)
            );

            const otherDebtObligations =
                calculationExecutionPlan?.otherDebtObligations;

            return otherDebtObligationsSameFrequency(
                otherDebtObligations,
                frequency
            );
        },
});

export const sectionSelectedState = atomFamily({
    key: 'SectionSelectedState',
    default: sectionSelectedDefault,
    effects_UNSTABLE: [persistAtom],
});
