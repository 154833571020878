import { Flex, Grid } from '@nestoca/ui';
import { useRecoilValue } from 'recoil';

import { EditableCell } from 'components/editable';
import { useSubjectPropertyPurposeOptions } from 'constants/property';
import { getHelocApplicationState } from 'store/heloc';
import { SectionDetailsProps } from 'types/submission-notes';
import { formatMoney, formatPercentage } from 'utils';

import { Comments } from './comments';
import styles from './section-details.module.scss';

export const HelocDetails = ({
    sectionCompleted,
    submissionNotes,
}: SectionDetailsProps) => {
    const { baseRate, bestRate, lenderName, product, purposeDescription } =
        submissionNotes?.applicationFields?.heloc || {};

    const { helocAdditionalComments } = submissionNotes?.customFields || {};

    const {
        firstPaymentDate,
        helocAmount,
        initialDrawdownAmount,
        initialUsedBalance,
    } =
        useRecoilValue(
            getHelocApplicationState(submissionNotes.applicationId)
        ) || {};

    const propertyPurposeOptions = useSubjectPropertyPurposeOptions();

    return (
        <>
            <Grid className={styles.grid}>
                <EditableCell
                    name="purposeDescription"
                    fieldType="readonly"
                    label="purpose"
                    options={propertyPurposeOptions}
                    value={purposeDescription}
                />
                <EditableCell
                    name="lenderName"
                    fieldType="readonly"
                    label="lender"
                    value={lenderName}
                />
                <EditableCell
                    name="productName"
                    fieldType="readonly"
                    label="productLabel"
                    value={product?.name}
                />
                <EditableCell
                    name="baseRate"
                    fieldType="readonly"
                    label="baseRate"
                    value={formatPercentage(baseRate)}
                />
                <EditableCell
                    name="bestRate"
                    fieldType="readonly"
                    label="bestRate"
                    value={formatPercentage(bestRate)}
                />
                <EditableCell
                    name="helocAmount"
                    fieldType="readonly"
                    label="helocAmount"
                    value={formatMoney(helocAmount)}
                />
                {initialUsedBalance && (
                    <EditableCell
                        name="initialUsedBalance"
                        fieldType="readonly"
                        label="initialUsedBalance"
                        value={formatMoney(initialUsedBalance)}
                    />
                )}
                {initialDrawdownAmount && (
                    <EditableCell
                        name="initialDrawdownAmount"
                        fieldType="readonly"
                        label="initialDrawdownAmount"
                        value={formatMoney(initialDrawdownAmount)}
                    />
                )}
                {firstPaymentDate && (
                    <EditableCell
                        name="firstPaymentDate"
                        fieldType="date"
                        label="firstPaymentDate"
                        value={firstPaymentDate}
                    />
                )}
            </Grid>
            <Flex className={styles['bottom-container']}>
                <Comments
                    isEditing={!sectionCompleted}
                    name="helocAdditionalComments"
                    label="comments"
                    value={helocAdditionalComments}
                />
            </Flex>
        </>
    );
};
